@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sans;
  }

  input {
    color: rgb(73, 80, 87);
  }
  select {
    color: rgb(73, 80, 87);
  }
}
