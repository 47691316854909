/* Generated by Font Squirrel (http://www.fontsquirrel.com) on October 17, 2015 */

@font-face {
  font-family: 'Averta';
  src: url('averta/averta-light-webfont.eot');
  src: url('averta/averta-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('averta/averta-light-webfont.woff2') format('woff2'),
    url('averta/averta-light-webfont.woff') format('woff'),
    url('averta/averta-light-webfont.ttf') format('truetype'),
    url('averta/averta-light-webfont.svg#avertalight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('averta/averta-regular-webfont.eot');
  src: url('averta/averta-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('averta/averta-regular-webfont.woff2') format('woff2'),
    url('averta/averta-regular-webfont.woff') format('woff'),
    url('averta/averta-regular-webfont.ttf') format('truetype'),
    url('averta/averta-regular-webfont.svg#avertaregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('averta/averta-semibold-webfont.eot');
  src: url('averta/averta-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('averta/averta-semibold-webfont.woff2') format('woff2'),
    url('averta/averta-semibold-webfont.woff') format('woff'),
    url('averta/averta-semibold-webfont.ttf') format('truetype'),
    url('averta/averta-semibold-webfont.svg#avertasemibold') format('svg');
  font-weight: 500;
  font-style: normal;
}
